import React, { PureComponent } from 'react';
import format from 'date-fns/format'
import { useStaticQuery, graphql, Link } from "gatsby";
import Image from 'gatsby-image';
import Layout from "../components/layout"
import { HEADER_THEME, HEADER_TYPE } from "../consts"

import '../styles/blog.scss';
import PortfolioItem from "../components/Portfolio"
import Container from "../components/container"

class Blog extends PureComponent {
  element = React.createRef();

  sizer = React.createRef();

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
  }

  render() {
    const { data: { allContentfulBlog: { edges } } } = this.props;

    return (
      <Layout isExternal headerType={{ type: HEADER_TYPE.BLOG, theme: HEADER_THEME.INVERSE }}>

        {edges.filter(({ node }) => node.isPinned).map(({ node }) => (
          <div className="flex flex-col md:flex-row md:pt-24">
            <Link to={`/blog/${node.slug}`} className="w-full md:w-1/2 lg:w-7/12 pinned-thumbnail">
              <Image
                tag="Thumbnail"
                className="thumbnail"
                fluid={node.thumbnail.fluid} />
            </Link>

            <div className="flex items-start md:items-end w-full md:w-1/2 lg:w-5/12 p-6 lg:p-0 lg:m-16 blog-main">
              <div className="flex flex-col items-start text-left md:items-start md:text-left">
                <span className="blog-category mb-4">{node.category}</span>

                <span className="blog-creation-time mb-2">{format(node.createdAt, "MMM.DD, YYYY")}</span>

                <Link to={`/blog/${node.slug}`}>
                  <h1 className="blog-title mb-6">{node.title}</h1>
                </Link>

                <p className="blog-short-description mb-10">{node.shortDescription}</p>

                <div className="flex items-center">
                  <div className="bg-cover bg-center h-10 w-10 rounded-full mr-6" style={{ backgroundImage: `url(${node.authorAvatar.file.url})`}} />
                  <span className="blog-author-name">{node.author}</span>
                </div>
              </div>
            </div>
          </div>
        ))}


        <div className="blog-main-bg pt-6 md:py-12">
          <Container>

            {/* <div className="">
              <ul className="flex flex-row flex-wrap items-center blog-category-filter">
                <li className="px-6 my-2 md:my-0">All</li>
                <li className="px-6 active my-2 md:my-0">Travel</li>
                <li className="px-6 my-2 md:my-0">Development</li>
                <li className="px-6 my-2 md:my-0">Branding</li>
              </ul>
            </div>
        */}
            <div className="mt-0 md:mt-8 md:-mx-6 px-6 md:p-0">
              <div className="my-shuffle">
                {edges.filter(({ node }) => !node.isPinned).map(({ node }) => (
                  <Link to={`/blog/${node.slug}`} className="blog-post-card py-6 md:p-6 md:w-1/3 w-full">
                    <div>
                      <div>
                        <div className="aspect aspect--16x9">

                          <div className="blog-category absolute">{node.tags[0]}</div>

                          <div className="aspect__inner">
                            <Image
                              fluid={node.thumbnail.fluid}
                              alt={node.title}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="blog-creation-time mt-4">{format(node.createdAt, "MMM.DD, YYYY")}</span>
                          <span className="blog-title mt-2 mb-4">{node.title}</span>

                          <p className="blog-short-description mb-4">{node.shortDescription}</p>

                          <div className="flex items-center">
                            <div className="bg-cover bg-center h-10 w-10 rounded-full mr-6" style={{ backgroundImage: `url(${node.authorAvatar.file.url})`}} />
                            <span className="blog-author-name">{node.author}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

              </div>
            </div>
          </Container>
        </div>

      </Layout>
    )
  }
}

export default Blog;

export const query = graphql`
    query {
      allContentfulBlog {
        edges {
          node {
            shortDescription
            isPinned
            author
            authorAvatar {
              file {
                url
              }
            }
            category
            title
            slug
            tags
            createdAt
            thumbnail {
              fluid(maxHeight: 540) {
                src
                aspectRatio
                srcWebp
                base64
                sizes
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `
